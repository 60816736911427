<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/home' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item
                :to="{
                    path: '/academic_Management/academic_font_management/academic_referee_system',
                }"
                >裁判系统</el-breadcrumb-item
            >
            <el-breadcrumb-item>城市赛裁判系统</el-breadcrumb-item>
        </el-breadcrumb>
        <el-page-header @back="goBack"></el-page-header>
        <el-row :gutter="15">
            <el-col span="12">
                <el-card style="margin-top: 15px">
                    <el-row class="title_row">
                        <el-col>
                            <span>条件筛选</span>
                        </el-col>
                    </el-row>
                    <el-form
                        ref="queryFormRef"
                        :model="queryForm"
                        :rules="queryFormRule"
                        label-width="80px"
                    >
                        <el-row :gutter="20">
                            <el-col :span="8">
                                <el-form-item label="赛季" prop="season">
                                    <el-select
                                        placeholder="请选择赛季"
                                        style="width: 100%"
                                        v-model="queryForm.season"
                                    >
                                        <el-option
                                            v-for="item in dict_season"
                                            :key="item.dictValue"
                                            :label="item.dictLabel"
                                            :value="item.dictValue"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="赛区" prop="districtId">
                                    <el-select
                                        style="width: 100%"
                                        v-model="queryForm.districtId"
                                        @change="matchInfoShow = false"
                                        ref="matchTypeRef"
                                    >
                                        <el-option
                                            v-for="item in dict_district"
                                            :key="item.id"
                                            :label="item.districtName"
                                            :value="item.id"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="8">
                                <el-form-item label="比赛组别" prop="level">
                                    <el-select
                                        style="width: 100%"
                                        v-model="queryForm.level"
                                        @change="matchInfoShow = false"
                                        ref="gameTypeRef"
                                    >
                                        <el-option
                                            v-for="item in dict_group"
                                            :key="item.dictCode"
                                            :label="item.dictLabel"
                                            :value="item.dictValue"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="20" type="flex" justify="end">
                            <el-button
                                type="primary"
                                style="width: 120px; margin-right: 10px"
                                @click="getMatchInfo()"
                                >确 认</el-button
                            >
                        </el-row>
                    </el-form>
                </el-card>
            </el-col>
            <el-col span="12">
                <el-card style="margin-top: 15px">
                    <el-row class="title_row">
                        <el-col>
                            <span>使用说明：</span>
                        </el-col>
                    </el-row>
                    <div class="tab-desc">
                        1、请先选择该场比赛的赛区和组别，并点击<span
                            style="color: rgb(64, 158, 255)"
                            >“确认”</span
                        >按钮。
                    </div>
                    <div class="tab-desc">
                        2、确认<span style="color: red">“签到结束”后</span
                        >，设置晋级人数，系统自动生成第一轮比赛。
                    </div>
                    <div class="tab-desc">
                        3、如遇上一轮比赛设置错误的情况。可点击<span
                            style="color: red"
                            >“比赛回退”</span
                        >按钮回退上一轮比赛
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <el-card style="margin-top: 15px">
            <el-row class="title_row">
                <el-col>
                    <span>裁判功能</span>
                </el-col>
            </el-row>
            <el-row>
                <el-button
                    type="primary"
                    plain
                    icon="el-icon-document-checked"
                    @click="showSignInInfoDialog()"
                    :disabled="!matchInfoShow"
                    >查看签到情况</el-button
                >
                <el-button
                    type="primary"
                    plain
                    icon="el-icon-setting"
                    :disabled="!matchInfoShow"
                    @click="showSetPromotionNumberDialog"
                    >设置晋级人数</el-button
                >
                <el-button
                    type="primary"
                    plain
                    icon="el-icon-s-data"
                    :disabled="!matchInfoShow"
                    @click="showRankInfoDialog()"
                    >查看实时排名</el-button
                >
                <el-button
                    type="warning"
                    plain
                    icon="el-icon-download"
                    :disabled="!matchInfoShow"
                    @click="downloadInfoList()"
                    >导出当前轮次晋级/淘汰名单</el-button
                >
                <el-button
                    type="danger"
                    plain
                    icon="el-icon-refresh"
                    :disabled="!matchInfoShow"
                    @click="resetAllRoundsDialogVisable = true"
                    >重置所有轮次</el-button
                >
            </el-row>
        </el-card>

        <el-tabs
            style="margin-top: 15px"
            class="cpxt"
            v-if="matchInfoShow"
            type="border-card"
            v-model="currentMatchInfoIndex"
            @tab-click="getMatchInfo('select')"
        >
            <el-tab-pane
                v-for="(item, i) in matchInfo"
                :label="'round' + item.round + '-' + item.roundName"
                :name="i + ''"
                :key="i"
            >
                <template slot="label">
                    <div
                        class="usual-tab-card"
                        :class="'usual-tab-card' + item.roundType"
                    >
                        {{ "round" + item.round + "-" + item.roundName }}
                    </div>
                </template>
                <el-row :gutter="20" class="tab-headview">
                    <el-col
                        :span="18"
                        style="font-size: 20px"
                        v-if="item.roundType !== -110"
                    >
                        <span style="margin-left: 30px; color: #67c23a"
                            >预晋级人数:{{ statisticsInfo.setPassNum }}</span
                        >
                        <span style="margin-left: 30px; color: #67c23a"
                            >已晋级人数:{{ statisticsInfo.passedNum }}</span
                        >
                        <span style="margin-left: 30px; color: #e6a23c"
                            >本轮人数:{{ statisticsInfo.curNum }}</span
                        >
                        <span style="margin-left: 30px; color: #e6a23c"
                            >本轮晋级:{{ statisticsInfo.curPass }}</span
                        >
                        <span style="margin-left: 30px; color: #e6a23c"
                            >本轮淘汰:{{ statisticsInfo.curOut }}</span
                        >
                        <span style="margin-left: 30px; color: #e6a23c"
                            >本轮待比赛:{{ statisticsInfo.curWait }}</span
                        >
                    </el-col>
                    <el-col
                        :span="18"
                        style="font-size: 20px"
                        v-if="item.roundType === -110"
                    >
                        <span style="margin-left: 20px; color: #fdba01"
                            >参赛人数:{{ statisticsInfo.userNum }}</span
                        >
                        <span style="margin-left: 20px; color: #67c23a"
                            >晋级人数:{{ statisticsInfo.promotedNum }}</span
                        >
                        <span style="margin-left: 20px; color: #ff0000"
                            >淘汰人数:{{ statisticsInfo.notPromotedNum }}</span
                        >
                    </el-col>
                    <el-col
                        :span="3"
                        style="text-align: right"
                        v-if="item.roundType !== -110"
                    >
                        <el-button
                            type="primary"
                            size="medium"
                            @click="showSetPromotionDialog(item)"
                            style="width: 100%"
                            v-if="showSetPromotionButton"
                            >设置晋级</el-button
                        >
                    </el-col>
                    <el-col :span="3" style="text-align: right">
                        <el-button
                            type="danger"
                            size="medium"
                            @click="resetMatch(item)"
                            style="width: 100%"
                            v-if="showBackButton"
                            >比赛回退</el-button
                        >
                    </el-col>
                </el-row>
                <el-table
                    height="100%"
                    header-cell-class-name="tableHeaderStyle"
                    :data="showPlayers"
                    border
                    :row-class-name="tableRowClassName"
                >
                    <el-table-column type="index" label="#"></el-table-column>
                    <el-table-column
                        label="学校"
                        prop="schoolName"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        label="年级"
                        prop="grade"
                        width="120"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        label="上场顺序号"
                        width="120"
                        prop="competitionNumber"
                        align="center"
                    ></el-table-column>
                    <el-table-column
                        label="选手姓名"
                        width="180"
                        prop="userName"
                        align="center"
                    >
                        <template scope="scope">
                            <span class="nationalnum"
                                >{{ scope.row.userName }}
                                {{
                                    scope.row.countryReserve === true
                                        ? "(保留)"
                                        : ""
                                }}</span
                            >
                        </template></el-table-column
                    >
                    <el-table-column
                        label="比赛号码牌"
                        width="120"
                        prop="competitionNumber"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <div class="nationalnum" style="color: red">
                                {{ scope.row.competitionNumber }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="晋级"
                        v-if="item.roundType !== -110"
                        width="160px"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-checkbox
                                border
                                label="晋级"
                                size="small"
                                :class="
                                    scope.row.promoted === '1'
                                        ? 'promoted-btn'
                                        : ''
                                "
                                v-model="scope.row.promoted"
                                true-label="1"
                                false-label=""
                                :key="scope.row.playerId"
                                @change="upDateStatisticsInfo(item, scope.row)"
                            >
                            </el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="淘汰"
                        v-if="item.roundType !== -110"
                        width="160px"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-checkbox
                                border
                                label="淘汰"
                                size="small"
                                :class="
                                    scope.row.promoted === '0'
                                        ? 'eliminate-btn'
                                        : ''
                                "
                                v-model="scope.row.promoted"
                                true-label="0"
                                false-label=""
                                :key="scope.row.playerId"
                                :style="{ '--fill-color': '#F56C6C' }"
                                @change="upDateStatisticsInfo(item, scope.row)"
                            >
                            </el-checkbox>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="晋级状态"
                        v-if="item.roundType === -110"
                        width="100"
                        align="center"
                    >
                        <template slot-scope="scope">
                            <el-tag
                                type="success"
                                v-if="scope.row.promoted === '1'"
                                >晋级</el-tag
                            >
                            <el-tag
                                type="danger"
                                v-else-if="scope.row.promoted === '0'"
                                >淘汰</el-tag
                            >
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="排名"
                        prop="promoted"
                        align="center"
                        width="100px"
                    >
                        <template slot-scope="scope">
                            <div
                                :class="
                                    scope.row.promoted === 1
                                        ? 'rank-success'
                                        : ''
                                "
                            >
                                {{ scope.row.ranking }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        label="名次"
                        v-if="item.roundType === -110"
                        prop="score_promoted"
                        width="100px"
                        align="center"
                    >
                        <!-- <template slot-scope="scope">
                            <div
                                :class="
                                    scope.row.promoted === 1
                                        ? 'rank-success'
                                        : ''
                                "
                            >
                                {{ scope.row.rankingName }}
                            </div>
                        </template> -->
                        <template slot-scope="scope">
                            <div
                                style="font-size: 16px; font-weight: 800"
                                :class="
                                    scope.row.rankingName === '冠军'
                                        ? 'guanjun'
                                        : scope.row.rankingName === '亚军'
                                        ? 'yajun'
                                        : scope.row.rankingName === '季军'
                                        ? 'jijun'
                                        : ''
                                "
                            >
                                {{ scope.row.rankingName }}
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <!-- 查看签到情况的Dialog -->
        <el-dialog
            :title="'签到情况（已签到 ' + signInInfo.length + ' 人）'"
            :visible.sync="signInInfoDialogVisable"
            width="60%"
        >
            <el-table
                style="margin-top: 0"
                header-cell-class-name="tableHeaderStyle"
                :data="signInInfo"
                border
            >
                <el-table-column type="index"></el-table-column>
                <el-table-column
                    label="学校"
                    prop="schoolName"
                ></el-table-column>
                <el-table-column label="姓名" prop="name">
                    <template scope="scope">
                        <span
                            >{{ scope.row.userName }}
                            {{
                                scope.row.countryReserve === true
                                    ? "(保留)"
                                    : ""
                            }}</span
                        >
                    </template>
                </el-table-column>
                <el-table-column label="年级" prop="grade"></el-table-column>
                <el-table-column
                    label="比赛号码牌（上场顺序）"
                    prop="competitionNumber"
                    width="180px"
                >
                    <template scope="scope">
                        <span style="color: green; font-size: 18px">{{
                            scope.row.competitionNumber
                        }}</span>
                    </template></el-table-column
                >
                <el-table-column
                    label="性别"
                    prop="gender"
                    :formatter="genderFormatter"
                ></el-table-column>
            </el-table>
        </el-dialog>
        <!-- 设置晋级人数的Dialog -->
        <el-dialog
            title="设置晋级人数"
            :visible.sync="setPromotionNumberDialogVisable"
            width="500px"
        >
            <el-form label-width="80px">
                <el-form-item label="晋级人数">
                    <el-input
                        type="number"
                        v-model="promotionNum"
                        :disabled="!setPromotionNumberEnable"
                    ></el-input>
                </el-form-item>
            </el-form>
            <span
                slot="footer"
                class="dialog-footer"
                v-if="setPromotionNumberEnable"
            >
                <el-button type="primary" @click="setPromotionNumber()"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 设置晋级的Dialog -->
        <el-dialog
            title="设置晋级"
            :visible.sync="setPromotionDialogVisible"
            width="50%"
        >
            <div style="font-size: 18px">
                本轮为：{{ currentTurnName }} ，参赛人数：{{
                    currentTurnUserNum
                }}
            </div>
            <div style="color: #f56c6c; font-size: 18px; margin-top: 40px">
                本轮淘汰选手：{{ setPromotionTipsOut.length }} 人,
                {{ rankTitleStr }}
            </div>
            <el-row>
                <el-col
                    v-for="(item, index) in setPromotionTipsOut"
                    :key="index"
                    :span="6"
                >
                    <span style="color: #f56c6c"
                        ><span style="font-weight: bold">{{
                            item.competitionNumber
                        }}</span
                        >号-{{ item.userName }}</span
                    >
                </el-col>
            </el-row>
            <div style="color: #67c23a; margin-top: 40px; font-size: 18px">
                本轮晋级选手：{{ setPromotionTipsPass.length }} 人,
                {{ rankTitleStrJ }}
            </div>
            <el-row>
                <el-col
                    v-for="(item, index) in setPromotionTipsPass"
                    :key="index"
                    :span="6"
                >
                    <span style="color: #67c23a"
                        ><span style="font-weight: bold">{{
                            item.competitionNumber
                        }}</span
                        >号-{{ item.userName }}</span
                    >
                </el-col>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button
                    type="danger"
                    v-if="setPromotionTipsPass.length > 1"
                    @click="promotTied()"
                    >并列晋级</el-button
                >
                <el-button @click="setPromotionDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="setPromotion()"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 查看实时排名的Dialog -->
        <el-dialog
            title="实时排名"
            :visible.sync="showRealTimeRankingDialogVisable"
            width="60%"
        >
            <el-table
                style="margin-top: 0"
                header-cell-class-name="tableHeaderStyle"
                :data="rankInfo"
                border
            >
                <el-table-column label="#" type="index"></el-table-column>
                <el-table-column label="姓名" prop="userName"></el-table-column>
                <el-table-column
                    label="比赛号码牌"
                    prop="competitionNumber"
                    width="90px"
                >
                    <template slot-scope="scope">
                        <div class="nationalnum" style="color: red">
                            {{ scope.row.competitionNumber }}
                        </div>
                    </template></el-table-column
                >
                <el-table-column
                    width="120px"
                    label="手机号"
                    prop="phone"
                ></el-table-column>
                <el-table-column
                    width="70px"
                    label="性别"
                    prop="sex"
                    :formatter="genderFormatter"
                ></el-table-column>
                <el-table-column
                    label="学校"
                    prop="schoolName"
                ></el-table-column>
                <el-table-column label="年级" prop="grade"></el-table-column>
                <el-table-column label="晋级状态" align="center">
                    <template slot-scope="scope">
                        <el-tag
                            type="danger"
                            v-if="scope.row.promoted === '0'"
                            size="mini"
                            >未晋级</el-tag
                        >
                        <el-tag
                            type="success"
                            v-else-if="scope.row.promoted === '1'"
                            size="mini"
                            >晋级</el-tag
                        >
                        <el-tag
                            type="success"
                            v-else-if="scope.row.promoted === '-1'"
                            size="warning"
                            >待比赛</el-tag
                        >
                        <span v-else>-</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="排名"
                    prop="promoted"
                    align="center"
                    width="100px"
                >
                    <template slot-scope="scope">
                        <div
                            :class="
                                scope.row.promoted === 1 ? 'rank-success' : ''
                            "
                        >
                            {{ scope.row.ranking }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    label="名次"
                    prop="score_promoted"
                    width="100"
                    align="center"
                >
                    <!-- <template slot-scope="scope">
                        <div
                            :class="
                                scope.row.promoted === 1 ? 'rank-success' : ''
                            "
                        >
                            {{ scope.row.rankingName }}
                        </div>
                    </template> -->
                    <template slot-scope="scope">
                        <div
                            style="font-size: 16px; font-weight: 800"
                            :class="
                                scope.row.rankingName === '冠军'
                                    ? 'guanjun'
                                    : scope.row.rankingName === '亚军'
                                    ? 'yajun'
                                    : scope.row.rankingName === '季军'
                                    ? 'jijun'
                                    : ''
                            "
                        >
                            {{ scope.row.rankingName }}
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <!-- 重置所有轮次 -->
        <el-dialog
            title="重置所有轮次"
            :visible.sync="resetAllRoundsDialogVisable"
            width="40%"
        >
            <el-form label-width="80px">
                <el-form-item label="输入密码">
                    <el-input type="text" v-model="password"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="resetAllRounds()"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {
  queryDistrictPage, setCityFinalRound, getPromotionNum, citySetPromotionNum, citySetPromotion, selectUserByRound, resetMatch,
  resetting, getMatchWinners, getSignInList, setPromotionPoint, setAbreastPromotion, getPromotionRanking
} from '@/http/api'
export default {
  data () {
    return {
      rankTitleStrJ: '',
      rankTitleStr: '',
      password: '',
      showPlayers: [],
      showRealTimeRankingDialogVisable: false,
      matchInfoShow: false,
      dict_group: this.$userInfo.dict_group(),
      dict_district: [],
      e_sex: this.$userInfo.getDataList(this, 'e_sex').then(value => { this.e_sex = value }),
      dict_grade: this.$userInfo.getGradeData(),
      queryFormRule: {
        districtId: [{ required: true, message: '请选择', trigger: 'change' }],
        level: [{ required: true, message: '请选择', trigger: 'change' }]
      },
      queryForm: {
        activityType: this.$chnEngStatusCode.activityType,
        raceSchedule: this.$route.query.raceSchedule,
        matchTypeName: this.$route.query.matchTypeName,
        districtId: '',
        season: this.$chnEngStatusCode.defaultSeason,
        level: ''
      },
      resetAllRoundsDialogVisable: false,
      matchInfo: [],
      signInInfoDialogVisable: false,
      setPromotionNumberDialogVisable: false,
      promotionNum: 0,
      setPromotionNumberEnable: false,
      signInInfo: [],
      rankInfo: [],
      currentMatchInfoIndex: '',
      dict_season: [],
      statisticsInfo: {
        setPassNum: '',
        passedNum: '',
        curNum: '',
        curPass: '',
        curOut: '',
        curWait: ''
      },
      setPromotionDialogVisible: false,
      setPromotionTipsOut: [],
      setPromotionTipsPass: [],
      setPromotionData: []
    }
  },
  created () {
    this.getDistrictList()
    this.getSeason()
  },
  methods: {
    getSeason () {
      this.$userInfo.getDataList(this, 't_season').then(value => {
        this.dict_season = value
      })
    },
    // 获取赛区筛选数据
    getDistrictList () {
      queryDistrictPage({ pageNum: 1, pageSize: 10000 }).then((res) => {
        this.dict_district = res.data.list
      }).catch((err) => {
        console.log('err', err)
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (row.roundType !== -110) {
        if (row.promoted === '0') {
          return 'warning-row'
        } else if (row.promoted === '1') {
          return 'success-row'
        } else {
          return ''
        }
      }
      return ''
    },
    goBack () {
      this.$router.back()
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    nationalnumFormatter (num) {
      return this.$xcUtils.addZero(num)
    },
    // 获取轮次信息
    getMatchInfo (flag) {
      if (flag === 'select') {
        this.getRoundPlayerInfo(this.matchInfo[this.currentMatchInfoIndex])
      } else {
        this.$refs.queryFormRef.validate(async valid => {
          if (!valid) return
          setCityFinalRound(this.queryForm).then((res) => {
            this.matchInfo = res.data
            if (this.matchInfo.length !== 0) {
              this.currentMatchInfoIndex = this.matchInfo.length - 1 + ''
              this.getRoundPlayerInfo(this.matchInfo[this.currentMatchInfoIndex])
            }
            this.matchInfoShow = true
          }).catch((err) => {
            console.log('返回的err', err)
          })
        })
      }
    },
    showSignInInfoDialog () {
      this.queryForm.signIn = true
      getSignInList(this.queryForm).then((res) => {
        res.data.forEach(item => {
          item.competitionNumber = this.nationalnumFormatter(item.competitionNumber)
        })
        this.signInInfo = res.data
        this.signInInfoDialogVisable = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    // 查看实时排名
    showRankInfoDialog () {
      getMatchWinners(this.queryForm).then((res) => {
        this.rankInfo = res.data.winners
        this.rankInfo.forEach((item, index) => {
          item.promoted = item.promoted + ''
          item.competitionNumber = this.nationalnumFormatter(item.competitionNumber)
        })
        this.showRealTimeRankingDialogVisable = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },

    showSetPromotionNumberDialog () {
      getPromotionNum(this.queryForm).then((res) => {
        this.promotionNum = res.data.promotionNum
        if (this.promotionNum < 1) {
          this.promotionNum = 0
          this.setPromotionNumberEnable = true
        } else {
          this.setPromotionNumberEnable = false
        }
        this.setPromotionNumberDialogVisable = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    setPromotionNumber () {
      if (this.promotionNum === 0) {
        return this.$message.warning('晋级人数不能为0')
      }
      this.queryForm.promotionNum = this.promotionNum
      citySetPromotionNum(this.queryForm).then((res) => {
        this.$message.success('设置成功')
        this.getMatchInfo()
        this.setPromotionNumberDialogVisable = false
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    genderFormatter (data) {
      var temp = '未设置'
      for (const key in this.e_sex) {
        if (Object.hasOwnProperty.call(this.e_sex, key)) {
          const element = this.e_sex[key]
          console.log('element.dictValue', element.dictValue)
          if (element.dictValue + '' === data.sex + '') {
            temp = element.dictLabel
          }
        }
      }
      return temp
    },
    showSetPromotionDialog (data) {
      this.setPromotionDataRequest(data)
    },
    // 处理晋级数据
    setPromotionDataRequest (data) {
      console.log('this.showPlayers', this.showPlayers)
      // 检查是否所有选手都设置了晋级状态
      var temp = []
      this.showPlayers.forEach(element => {
        if (element.promoted === '-1' || element.promoted === '') {
          temp.push(element)
        }
      })
      if (temp.length > 0) {
        var tempStr = ''
        temp.forEach(element => {
          tempStr += element.competitionNumber + '号-' + element.userName + '<br>'
          // tempStr += `${element.competitionNumber}号-${element.userName}`
        })
        return this.$alert(tempStr, '存在选手未设置晋级', {
          confirmButtonText: '确定',
          dangerouslyUseHTMLString: true
        })
      }
      // 再次确认本轮晋级情况
      // 初始化数据
      this.setPromotionTipsOut = []
      this.setPromotionTipsPass = []
      this.showPlayers.forEach(element => {
        if (element.promoted === '1') {
          this.setPromotionTipsPass.push(element)
        }
        if (element.promoted === '0') {
          this.setPromotionTipsOut.push(element)
        }
      })
      this.setPromotionData = data
      this.getEliminationRankingInformation()
    },

    // 获取淘汰排名信息
    getEliminationRankingInformation () {
      var playerIds = [] // 晋级比赛选手id
      this.showPlayers.forEach(element => {
        if (element.promoted === '1') {
          playerIds.push(element.playerId)
        }
      })
      this.queryForm.playerIds = playerIds
      this.queryForm.finalMatchId = this.setPromotionData.id
      getPromotionRanking(this.queryForm).then((res) => {
        if (res.data.notPromotionRanking) {
          this.rankTitleStr = '获得名次：第' + res.data.notPromotionRanking + '名'
        } else {
          this.rankTitleStr = ''
        }
        if (res.data.promotionRanking) {
          this.rankTitleStrJ = '获得名次：第' + res.data.promotionRanking + '名'
        } else {
          this.rankTitleStrJ = ''
        }
        this.setPromotionDialogVisible = true
      }).catch((err) => {
        console.log('返回的err', err)
      })
    },
    setPromotion (flag) {
      var playerIds = [] // 晋级比赛选手id
      this.showPlayers.forEach(element => {
        if (element.promoted === '1') {
          playerIds.push(element.playerId)
        }
      })
      this.queryForm.playerIds = playerIds
      this.queryForm.finalMatchId = this.setPromotionData.id
      if (flag === 'promotTied') {
        setAbreastPromotion(this.queryForm).then((res) => {
          this.$curUtils.closeLoading(this)
          this.$message.success('设置晋级成功')
          this.setPromotionDialogVisible = false
          this.getMatchInfo()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      } else {
        citySetPromotion(this.queryForm).then((res) => {
          this.$curUtils.closeLoading(this)
          this.$message.success('设置晋级成功')
          this.setPromotionDialogVisible = false
          this.getMatchInfo()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }
    },
    upDateStatisticsInfo (data, rowData) {
      console.log('data', rowData)
      this.statisticsInfo.setPassNum = data.finalMathPromotedNum
      this.statisticsInfo.passedNum = data.winnwePromotedNum
      this.statisticsInfo.curNum = 0
      this.statisticsInfo.curPass = 0
      this.statisticsInfo.curOut = 0
      this.statisticsInfo.curWait = 0
      this.showPlayers.forEach(element => {
        this.statisticsInfo.curNum++
        if (element.promoted === '1') {
          this.statisticsInfo.curPass++
        } else if (element.promoted === '0') {
          this.statisticsInfo.curOut++
        } else {
          this.statisticsInfo.curWait++
        }
      })
      if (rowData) {
        this.setPromotedData(rowData)
      }
    },
    async setPromotedData (rowData) {
      if (rowData.promoted !== '' && rowData.promoted !== '-1') {
        console.log('this.queryForm', this.queryForm)
        setPromotionPoint({
          ...this.queryForm,
          ...{
            playerId: rowData.playerId,
            promoted: rowData.promoted
          }
        }).then((res) => {
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }
    },
    // 获取轮次下指定选手列表
    getRoundPlayerInfo (showSchedule) {
      console.log('showSchedule', showSchedule)
      if (showSchedule.roundType === -110) { // 晋级名单单独处理
        getMatchWinners(this.queryForm).then((res) => {
          this.showPlayers = res.data.winners.map(item => {
            item.promoted = item.promoted + ''
            return item
          })
          this.statisticsInfo.userNum = res.data.userNum
          this.statisticsInfo.promotedNum = res.data.promotedNum
          this.statisticsInfo.notPromotedNum = res.data.notPromotedNum
        }).catch((err) => {
          console.log('返回的err', err)
        })
      } else {
        selectUserByRound({ finalMatchId: showSchedule.id }).then((res) => {
          this.showPlayers = res.data.map(item => {
            item.promoted = item.promoted + ''
            return item
          })
          this.upDateStatisticsInfo(showSchedule)
        }).catch((err) => {
          console.log('返回的err', err)
        })
      }
    },
    matchOver () {
      this.$confirm('比赛结束将由“已晋级选手”和“本轮晋级选手”直接生成晋级名单, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.setPromotion('matchOver')
      })
    },
    resetMatch (item) {
      this.$confirm('比赛回退将重新返回上一轮比赛未设置晋级的状态, 是否确认?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        this.queryForm.finalMatchId = item.id
        resetMatch(this.queryForm).then((res) => {
          this.$message.success('比赛回退成功')
          this.getMatchInfo()
        }).catch((err) => {
          console.log('返回的err', err)
        })
      })
    },
    promotTied () {
      this.$confirm('并列晋级将由“本轮晋级选手”并列排名晋级, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.setPromotion('promotTied')
      })
    },
    // 下载签到表
    downloadInfoList () {
      var downLoadUrl = ''
      if (this.matchInfo[this.currentMatchInfoIndex].roundType === -110) {
        downLoadUrl = this.$env.baseIP + 'cityMatch/final/exportMatchWinners'
      } else {
        downLoadUrl = this.$env.baseIP + 'cityMatch/final/excelUserByRound'
      }
      this.queryForm.finalMatchId = this.matchInfo[this.currentMatchInfoIndex].id
      this.$downLoadFile.downLoadFileWithUrl(this, downLoadUrl, '', this.queryForm)
    },
    // 重置所有轮次
    resetAllRounds () {
      if (this.password !== '1qazxsw2cz') {
        return this.$message.error('密码错误！')
      }
      this.queryForm.password = this.password
      resetting(this.queryForm).then((res) => {
        this.resetAllRoundsDialogVisable = false
        this.getMatchInfo()
      }).catch((err) => {
        console.log('返回的err', err)
      })
    }
  },
  computed: {
    showSetPromotionButton () {
      // var schduleArray = []
      // for (let index = 0; index < this.matchInfo.length; index++) {
      //   const element = this.matchInfo[index]
      //   if (element.roundType !== -110) {
      //     schduleArray.push(element)
      //   }
      // }
      return (Number(this.currentMatchInfoIndex) === this.matchInfo.length - 1 && this.matchInfo[this.matchInfo.length - 1].roundType !== -110)
    },
    showBackButton () {
      // var schduleArray = []
      // for (let index = 0; index < this.matchInfo.length; index++) {
      //   const element = this.matchInfo[index]
      //   if (element.roundType !== -110) {
      //     schduleArray.push(element)
      //   }
      // }
      return (Number(this.currentMatchInfoIndex) === this.matchInfo.length - 1) && (Number(this.currentMatchInfoIndex) !== 0)
    },
    currentTurnName () {
      if (this.matchInfo[this.currentMatchInfoIndex] !== undefined) {
        return this.matchInfo[this.currentMatchInfoIndex].roundName
      }
      return ''
    },
    currentTurnUserNum () {
      if (this.showPlayers) {
        return this.showPlayers.length
      }
      return ''
    }
  }
}
</script>

<style lang="less" scoped>
.el-tab-pane {
    height: calc(100vh - 110px);
    overflow-y: auto;
}
.el-table {
    margin-top: 56px;
    z-index: 99;
}
.tab-headview {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;
    background-color: white;
    padding: 12px 0px;
    line-height: 36px;
}
.nationalnum {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    letter-spacing: 1px;
}

.guanjun {
    color: #ffae5d;
}
.yajun {
    color: #cccccc;
}
.jijun {
    color: #b86d29;
}
.tab-desc {
    font-size: 14px;
    margin-bottom: 14px;
}
/deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item.is-active {
    border-right-color: transparent;
    border-left-color: transparent;
    height: 50px;
    line-height: 56px;
    // background: #409EFF;
    color: #fff;
    font-size: 18px;
    border-radius: 20px 20px 0 0;
    .usual-tab-card {
        background: #409eff;
        padding-left: 24px;
        padding-right: 24px;
    }
}
</style>
